.dashboard {
    padding: 2rem;
    max-width: 1200px;
    margin: auto;
    text-align: center;
}

.dashboard h1 {
    font-size: 2.5rem;
    color: #2c3e50;
    margin-bottom: 1rem;
}

.dashboard p {
    font-size: 1.2rem;
    color: #7f8c8d;
}

.services-section {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 2rem;
    margin-top: 2rem;
}

.service-card {
    background: white;
    padding: 2rem;
    border-radius: 12px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 300px;
    transition: transform 0.3s ease;
    cursor: pointer;
}

.service-card:hover {
    transform: translateY(-5px);
}

.service-card h2 {
    font-size: 1.8rem;
    color: #3498db;
}

.service-card p {
    font-size: 1rem;
    color: #34495e;
    margin: 0.5rem 0 1rem;
}

.service-card h3 {
    font-size: 1.2rem;
    color: #2c3e50;
    margin-top: 1rem;
}

.service-card ul {
    list-style-type: none;
    padding: 0;
    color: #7f8c8d;
    font-size: 0.95rem;
    text-align: left;
}

.service-card ul li {
    margin-bottom: 0.5rem;
}

.explore-btn {
    background-color: #3498db;
    color: white;
    padding: 0.8rem;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-size: 1rem;
    font-weight: 600;
    transition: all 0.3s ease;
    margin-top: 1rem;
}

.explore-btn:hover {
    background-color: #2980b9;
}

.coming-soon {
    margin-top: 3rem;
    padding: 2rem;
    background: #f8f9fa;
    border-radius: 12px;
}

.coming-soon h2 {
    font-size: 2rem;
    color: #e67e22;
}

.coming-soon p {
    font-size: 1.1rem;
    color: #7f8c8d;
}