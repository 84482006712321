/* Main Container */
.currency-exchange {
    padding: 2rem;
    max-width: 1200px;
    margin: 80px auto 0;
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

/* Exchange Container */
.exchange-container {
    display: flex;
    gap: 2rem;
    justify-content: space-between;
}

/* Exchange Card */
.exchange-card {
    background: white;
    padding: 2rem;
    border-radius: 12px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    flex: 2;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.exchange-card:hover {
    transform: translateY(-3px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.currency-selectors {
    display: flex;
    gap: 1rem;
    align-items: center;
    margin-top: 1rem;
}

/* Result Section */
.result {
    background: white;
    padding: 1.5rem;
    border-radius: 12px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
    flex: 1;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.result h2 {
    font-size: 1.5rem;
    color: #2c3e50;
    margin-bottom: 1rem;
}

.conversion-result {
    font-size: 1.4rem;
    color: #333;
    font-weight: bold;
    margin: 0.5rem 0;
}

.converted-amount {
    font-size: 2rem;
    font-weight: 700;
    color: #2c3e50;
}

.rate-info {
    color: #666;
    font-size: 0.9rem;
}

/* Form Styling */
.form-group {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.form-group label {
    font-weight: 500;
    color: #333;
    font-size: 1rem;
}

.form-group input,
.form-group select {
    padding: 0.8rem;
    border: 1px solid #ddd;
    border-radius: 8px;
    font-size: 1rem;
    transition: all 0.3s ease;
    background: #f9f9f9;
}

.form-group input:focus,
.form-group select:focus {
    border-color: #3498db;
    outline: none;
    box-shadow: 0 0 0 2px rgba(52, 152, 219, 0.2);
}

/* Swap Button */
.swap-button {
    background: #3498db;
    color: white;
    border: none;
    border-radius: 50%;
    width: 3rem;
    height: 3rem;
    font-size: 1.5rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: transform 0.3s ease, background 0.3s ease;
}

.swap-button:hover {
    background: #2980b9;
    transform: rotate(180deg);
}

/* Convert Button */
.convert-btn {
    width: 100%;
    background-color: #3498db;
    color: white;
    padding: 1rem;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-size: 1rem;
    font-weight: 600;
    transition: background 0.3s ease, transform 0.2s ease;
    margin-top: 1.5rem;
}

.convert-btn:hover {
    background-color: #2980b9;
    transform: translateY(-2px);
}

/* Exchange Rates Section */
.exchange-rates {
    background: white;
    padding: 2rem;
    border-radius: 12px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.exchange-rates h2 {
    text-align: center;
    color: #2c3e50;
    margin-bottom: 1.5rem;
    font-size: 1.8rem;
}

.rates-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 1.5rem;
}

/* Responsive Design */
@media (max-width: 1024px) {
    .exchange-container {
        flex-direction: column;
        align-items: center;
    }

    .exchange-card,
    .result {
        width: 100%;
    }
}

@media (max-width: 768px) {
    .currency-exchange {
        padding: 1rem;
    }

    .currency-selectors {
        flex-direction: column;
    }

    .swap-button {
        width: 2.5rem;
        height: 2.5rem;
        font-size: 1.2rem;
    }
}