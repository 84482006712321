.bill-reminders {
    padding: 2rem;
    max-width: 1400px;
    margin: 80px auto 0;
}

.bills-container {
    display: flex;
    justify-content: space-between;
    gap: 2rem;
}

.add-bill-section,
.bills-list-section {
    background: white;
    padding: 2rem;
    border-radius: 15px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    width: 48%;
}

.bill-form {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.form-row {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
}

.form-group {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.form-group label {
    font-weight: 500;
    color: #2c3e50;
}

.form-group input,
.form-group select {
    padding: 0.8rem;
    border: 1px solid #ddd;
    border-radius: 8px;
    font-size: 1rem;
    transition: all 0.3s ease;
}

.form-group input:focus,
.form-group select:focus {
    border-color: #3498db;
    outline: none;
    box-shadow: 0 0 0 2px rgba(52, 152, 219, 0.2);
}

.add-bill-btn {
    background-color: #3498db;
    color: white;
    padding: 1rem;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-size: 1rem;
    font-weight: 600;
    transition: all 0.3s ease;
    margin-top: 1rem;
}

.add-bill-btn:hover {
    background-color: #2980b9;
    transform: translateY(-2px);
}

.bills-list {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-top: 1rem;
}

.bill-item {
    background: #f8f9fa;
    padding: 1.5rem;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: transform 0.3s ease;
}

.bill-item:hover {
    transform: translateX(5px);
}

.bill-info h3 {
    margin: 0;
    font-size: 1.2rem;
    color: #333;
}

.amount {
    color: #3498db;
    font-weight: bold;
    margin: 0.5rem 0;
}

.due-date {
    font-size: 0.9rem;
    color: #7f8c8d;
}

.category {
    font-size: 0.9rem;
    color: #95a5a6;
}

.urgent-tag {
    color: #e74c3c;
    font-weight: bold;
    margin-left: 8px;
}

.bill-actions {
    display: flex;
    gap: 0.5rem;
}

.status-btn {
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-weight: 600;
    transition: background 0.3s ease;
}

.status-btn.pending {
    background-color: #f1c40f;
    color: white;
}

.status-btn.pending:hover {
    background-color: #f39c12;
}

.status-btn.paid {
    background-color: #2ecc71;
    color: white;
}

.status-btn.paid:hover {
    background-color: #27ae60;
}

.delete-btn {
    background-color: #e74c3c;
    color: white;
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-weight: 600;
    transition: background 0.3s ease;
}

.delete-btn:hover {
    background-color: #c0392b;
}

.no-bills {
    text-align: center;
    font-size: 1.1rem;
    color: #7f8c8d;
    padding: 2rem;
    background: #ecf0f1;
    border-radius: 10px;
}