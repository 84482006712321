.landing-page section {
    padding: 6rem 2rem;
}

/* Hero Section */
.hero-section {
    min-height: 100vh;
    background: linear-gradient(135deg, #1a237e, #0d47a1);
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    position: relative;
    overflow: hidden;
}

.hero-section::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200"><path fill="%23FFFFFF10" d="M0 0h200v200H0z"/></svg>') repeat;
    opacity: 0.1;
}

.hero-content {
    max-width: 800px;
    margin: 0 auto;
    position: relative;
    z-index: 1;
}

.hero-section h1 {
    font-size: 4rem;
    margin-bottom: 1.5rem;
    font-weight: 800;
    letter-spacing: -0.5px;
}

.hero-section p {
    font-size: 1.5rem;
    margin-bottom: 2rem;
    opacity: 0.9;
}

.hero-stats {
    display: flex;
    gap: 4rem;
    margin-top: 4rem;
}

.stat-item {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.stat-number {
    font-size: 2.5rem;
    font-weight: 700;
    color: #fff;
}

.stat-label {
    font-size: 1rem;
    color: rgba(255, 255, 255, 0.8);
    margin-top: 0.5rem;
}

.animate-text {
    animation: fadeInUp 1s ease-out;
}

.animate-text-delay {
    animation: fadeInUp 1s ease-out 0.5s both;
}

.cta-button {
    margin-top: 2rem;
    padding: 1.2rem 3rem;
    font-size: 1.2rem;
    background-color: #ff4081;
    color: white;
    border: none;
    border-radius: 50px;
    cursor: pointer;
    transition: all 0.3s ease;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 1px;
}

.cta-button:hover {
    transform: translateY(-2px);
    background-color: #f50057;
    box-shadow: 0 5px 15px rgba(255, 64, 129, 0.4);
}

/* Features Section */
.features-section {
    background-color: #f8f9fa;
    position: relative;
}

.features-section h2,
.services-section h2,
.faqs-section h2,
.testimonials-section h2 {
    text-align: center;
    font-size: 2.5rem;
    margin-bottom: 3rem;
    color: #1a237e;
}

.features-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 2rem;
    max-width: 1200px;
    margin: 0 auto;
}

.feature-card {
    background: white;
    padding: 2rem;
    border-radius: 20px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
    text-align: center;
}

.feature-icon {
    font-size: 3rem;
    margin-bottom: 1rem;
}

.feature-card:hover {
    transform: translateY(-10px);
}

/* Services Section */
.services-section {
    background: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%);
    padding: 6rem 2rem;
}

.services-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 2rem;
    max-width: 1200px;
    margin: 0 auto;
}

.service-card {
    background: white;
    border-radius: 20px;
    overflow: hidden;
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
}

.service-card:hover {
    transform: translateY(-10px);
}

.service-content {
    padding: 2rem;
}

.service-features {
    list-style: none;
    padding: 0;
    margin: 1.5rem 0;
}

.service-features li {
    padding: 0.5rem 0;
    color: #666;
    display: flex;
    align-items: center;
}

.service-features li::before {
    content: '✓';
    color: #4caf50;
    margin-right: 0.5rem;
    font-weight: bold;
}

.service-btn {
    width: 100%;
    padding: 1rem;
    background-color: #1a237e;
    color: white;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-weight: 600;
}

.service-btn:hover {
    background-color: #0d47a1;
}

/* FAQs Section */
.faq-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 2rem;
    max-width: 1200px;
    margin: 0 auto;
}

.faq-item {
    background: white;
    padding: 2rem;
    border-radius: 20px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
}

/* Testimonials Section */
.testimonials-section {
    background: linear-gradient(135deg, #1a237e, #0d47a1);
    color: white;
    position: relative;
}

.testimonials-section h2 {
    color: white;
}

.testimonials-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 2rem;
    max-width: 1200px;
    margin: 0 auto;
}

.testimonial-card {
    background: rgba(255, 255, 255, 0.1);
    padding: 2rem;
    border-radius: 20px;
    backdrop-filter: blur(10px);
    border: 1px solid rgba(255, 255, 255, 0.2);
}

.testimonial-rating {
    color: #ffd700;
    font-size: 1.5rem;
    margin-bottom: 1rem;
}

.testimonial-author {
    display: flex;
    align-items: center;
    margin-top: 1.5rem;
}

.author-image {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-right: 1rem;
}

.author-details h4 {
    margin: 0;
    font-size: 1.1rem;
}

.author-details p {
    margin: 0;
    font-size: 0.9rem;
    opacity: 0.8;
}

@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translateY(20px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@media (max-width: 768px) {
    .hero-section h1 {
        font-size: 3rem;
    }

    .hero-stats {
        flex-direction: column;
        gap: 2rem;
    }

    .services-grid,
    .features-grid,
    .testimonials-grid {
        grid-template-columns: 1fr;
    }
}