/* General styling */
.expense-tracker {
    padding: 1.5rem;
    width: 100%;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    background-color: #f4f6f9;
}

/* Summary Cards */
.summary-cards {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    /* Full-width, 3 cards in a row */
    gap: 1.5rem;
}

.summary-card {
    background-color: #ffffff;
    padding: 2rem;
    border-radius: 12px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
    text-align: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.summary-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.summary-card h3 {
    margin: 0;
    color: #444;
    font-size: 1.2rem;
}

.summary-card p {
    margin: 1rem 0 0;
    font-size: 2.2rem;
    font-weight: bold;
}

.summary-card.income p {
    color: #2ecc71;
}

.summary-card.expenses p {
    color: #e74c3c;
}

.summary-card.balance p {
    color: #3498db;
}

/* Main Content Layout */
.main-content {
    display: flex;
    gap: 2rem;
    flex-direction: row;
    width: 100%;
}

.transaction-form-container,
.transaction-history {
    flex: 1;
    /* Take up equal space */
    background: white;
    padding: 2rem;
    border-radius: 12px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.transaction-form-container:hover,
.transaction-history:hover {
    transform: translateY(-2px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

/* Form Styling */
.transaction-form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.form-group {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.form-group label {
    font-weight: 500;
    color: #333;
}

.form-group input,
.form-group select {
    padding: 0.8rem;
    border: 1px solid #ddd;
    border-radius: 8px;
    font-size: 1rem;
    transition: border-color 0.3s ease;
}

.form-group input:focus,
.form-group select:focus {
    border-color: #3498db;
    outline: none;
}

.submit-btn {
    background-color: #3498db;
    color: white;
    padding: 1rem;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-size: 1rem;
    font-weight: 600;
    transition: all 0.3s ease;
}

.submit-btn:hover {
    background-color: #2980b9;
    transform: translateY(-2px);
}

/* Transaction History Styling */
.transactions-list {
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.transaction-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    border-radius: 8px;
    background-color: #f8f9fa;
    transition: transform 0.3s ease;
}

.transaction-item:hover {
    transform: translateX(5px);
}

.transaction-item.expense {
    border-left: 4px solid #e74c3c;
}

.transaction-item.income {
    border-left: 4px solid #2ecc71;
}

.transaction-info h3 {
    margin: 0;
    font-size: 1.1rem;
    color: #2c3e50;
}

.transaction-info p {
    margin: 0.2rem 0;
    color: #666;
    font-size: 0.9rem;
}

.transaction-info small {
    color: #999;
    font-size: 0.8rem;
}

.transaction-amount {
    font-weight: bold;
    font-size: 1.2rem;
}

.expense .transaction-amount {
    color: #e74c3c;
}

.income .transaction-amount {
    color: #2ecc71;
}

.no-transactions {
    text-align: center;
    padding: 2rem;
    color: #666;
}

/* Responsive Design */
@media (max-width: 1024px) {
    .main-content {
        flex-direction: column;
    }

    .summary-cards {
        grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
        /* Stack cards on smaller screens */
    }
}

@media (max-width: 768px) {
    .expense-tracker {
        padding: 1rem;
    }

    .summary-cards {
        grid-template-columns: 1fr;
        /* Single column for small screens */
    }
}