/* Navbar Container */
.navbar {
    position: fixed;
    top: 0;
    width: 97%;
    height: 70px;
    background-color: transparent;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 2rem;
    z-index: 1000;
    transition: all 0.3s ease;
}

.navbar.scrolled {
    background-color: rgba(255, 255, 255, 0.95);
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(10px);
    height: 60px;
}

/* Brand Styling */
.navbar-brand .logo {
    height: 40px;
    transition: transform 0.3s ease;
}

.navbar.scrolled .navbar-brand .logo {
    transform: scale(0.9);
}

/* Links Styling */
.navbar-links {
    display: flex;
    gap: 2rem;
    align-items: center;
}

.navbar-links .link {
    color: black;
    text-decoration: none;
    font-weight: 500;
    font-size: 1.1rem;
    transition: color 0.3s ease, background-color 0.3s ease;
    padding: 0.5rem 1rem;
    border-radius: 5px;
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.navbar.scrolled .navbar-links .link {
    color: #1a237e;
}

.navbar-links .link:hover {
    background-color: rgba(255, 255, 255, 0.2);
}

.navbar.scrolled .navbar-links .link:hover {
    background-color: #f0f0f5;
    color: #ff4081;
}

/* Dropdown Styling */
.dropdown {
    position: relative;
}

.dropdown.open .dropdown-toggle {
    background-color: rgba(255, 255, 255, 0.2);
}

.navbar.scrolled .dropdown.open .dropdown-toggle {
    background-color: #f0f0f5;
    color: #ff4081;
}

.dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    background-color: rgba(255, 255, 255, 0.95);
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    padding: 0.5rem 0;
    display: flex;
    flex-direction: column;
    opacity: 0;
    visibility: hidden;
    transform: translateY(10px);
    transition: all 0.3s ease;
    z-index: 1;
}

.dropdown.open .dropdown-menu {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
}

.dropdown-item {
    padding: 0.8rem 1.5rem;
    font-size: 1rem;
    color: #1a237e;
    text-decoration: none;
    white-space: nowrap;
    transition: background-color 0.3s ease;
}

.dropdown-item:hover {
    background-color: #f0f0f5;
    color: #ff4081;
}

/* Dashboard Button Styling */
.dashboard-btn {
    background-color: #ff4081;
    color: white !important;
    padding: 0.8rem 1.5rem !important;
    border-radius: 50px !important;
    font-weight: 600 !important;
    transition: background-color 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease;
}

.dashboard-btn:hover {
    background-color: #f50057;
    transform: translateY(-2px);
    box-shadow: 0 5px 15px rgba(255, 64, 129, 0.4);
}

/* Responsive Styles */
@media (max-width: 768px) {
    .navbar {
        padding: 0 1rem;
    }

    .navbar-brand .logo {
        height: 36px;
    }

    .navbar-links {
        display: none;
        flex-direction: column;
        position: absolute;
        top: 70px;
        right: 0;
        background-color: rgba(255, 255, 255, 0.95);
        padding: 1rem 2rem;
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
        gap: 1rem;
        border-radius: 5px;
        width: auto;
        z-index: 1000;
    }

    .navbar.scrolled .navbar-links {
        display: flex;
    }

    .navbar-links .link {
        color: #1a237e;
        font-size: 1rem;
        padding: 0.75rem 1rem;
    }

    .dropdown-menu {
        position: static;
        box-shadow: none;
        padding: 0;
        transform: none;
        opacity: 1;
        visibility: visible;
    }

    .dropdown-item {
        padding: 0.5rem 1rem;
    }
}