.footer {
    background-color: #2c3e50;
    color: white;
    padding: 3rem 2rem 1rem;
}

.footer-content {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 2rem;
    max-width: 1200px;
    margin: 0 auto;
}

.footer-section h3 {
    margin-bottom: 1rem;
    font-size: 1.2rem;
}

.footer-section ul {
    list-style: none;
    padding: 0;
}

.footer-section ul li {
    margin-bottom: 0.5rem;
    cursor: pointer;
}

.footer-section ul li:hover {
    color: #3498db;
}

.footer-bottom {
    margin-top: 2rem;
    padding-top: 1rem;
    text-align: center;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
}